import React from 'react'
import BreadcrumbArea from '../../../components/BreadcrumbArea/BreadcrumbArea'
import Layout from '../../../layouts/Layout'
import ImageComp from './ImageComp'
import DetailsArea from './DetailsArea'
import MobileCard from './MobileCard'

function MobileDevelopment() {
  return (
    <Layout header={1} footer={1} className="" mainClassName="">
       <BreadcrumbArea
        title={"Mobile Development"}
        subtitle={"services/ mobile-development"}
        showShape={false}
        className={" breadcrumb-area-two pt-175"}
      />
     <ImageComp/>
     <DetailsArea/>
     <MobileCard/>
    </Layout>
  )
}

export default MobileDevelopment