import React from "react";
import TeamAreaTwoItem from "./TeamAreaTwoItem";

const TeamAreaThree = () => {
  const team_members = [
    {
      src: "/img/team/mayank.png",
      url: "/team-details",
      title: "Mayank Kumar Rajoria",
      designation: "Senior Software Engineer",
      description: "As our Senior Software Engineer, Mayank leads with expertise and vision, ensuring each project is innovative and impactful. His strategic guidance and technical insights are a cornerstone of our success at Kodeklan."
    },
    {
      src: "/img/team/nishi.png",
      url: "/team-details",
      title: "Nishi Sharma",
      designation: "Digital Marketing Manager",
      description: "Nishi oversees our digital marketing as the Digital Marketing Manager, crafting campaigns that boost client visibility and growth. Her keen understanding of trends and audience engagement makes her an essential driver of success."
    },
    {
      src: "/img/team/nikhil.png",
      url: "/team-details",
      title: "Nikhil Pundir",
      designation: "Full Stack Developer",
      description: "Nikhil is a versatile Full Stack Developer skilled in both frontend and backend technologies. His adaptability and strong problem-solving drive the quality of our solutions, making every project comprehensive and robust."
    },
    {
      src: "/img/team/vardhan.png",
      url: "/team-details",
      title: "Vardhan Chaudhary",
      designation: "Backend Developer",
      description: "As our Backend Developer, Vardhan creates secure, high-performance systems that power our applications. His commitment to reliability and scalability forms the backbone of our digital solutions, enabling smooth performance."
    },
    {
      src: "/img/team/mohit.png",
      url: "/team-details",
      title: "Mohit Gupta",
      designation: "Frontend Developer",
      description: "Mohit specializes in crafting responsive, engaging user interfaces with a focus on usability. His dedication to intuitive design ensures every interface offers a seamless and immersive experience."
    },
    {
      src: "/img/team/champalal.png",
      url: "/team-details",
      title: "Champa Lal Suthar",
      designation: "Full Stack Developer",
      description: "Champalal builds user-friendly, high-performance websites that are visually engaging and technically sound. His eye for detail in analyzing, optimizing, and debugging each project ensures flawless digital experiences."
    },
    {
      src: "/img/team/shreya.png",
      url: "/team-details",
      title: "Shreya Mahajan",
      designation: "Digital Marketing Executive",
      description: "As our Digital Marketing Executive, Shreya supports campaign development and execution with creativity and precision. Her dedication to enhancing brand reach and engagement is a vital part of our marketing success."
    },
  ];

  return (
    <section className="team-area-two pt-110 pb-100">
      <div className="container">
        <div className="row justify-content-center">
          {team_members.map((x, index) => (
            <div key={index} className="col-lg-4 col-md-6 col-sm-10">
              <TeamAreaTwoItem item={x} className="team-item-hover" />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamAreaThree;
