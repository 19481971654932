import React from "react";

const IdeationDetail = () => {
  return (
    <section className="blog-details-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="blog-details-wrap">
              <div className="bd-content-bottom">
                <h2 className="title">
                  Transform Your Ideas into Innovative Solutions
                </h2>
                <p>
                  {/* Effective ideation and design are crucial for developing products that resonate with users and meet market demands. Our comprehensive ideation and design services help you bring your concepts to life through creativity and strategic thinking. */}
                  Effective Creativity and design are pivotal for developing
                  products that reverberate with druggies and meet request
                  demands. Our comprehensive creativity and design services help
                  you bring your generalities to life through creativity and
                  strategic thinking.
                </p>
                {/* <h2 className="title">
                    Our Comprehensive Mobile App Development Services Include:
                </h2> */}
                {/* <ul className="list-wrap">
                  <li>
                    <span> Concept Development</span>
                   
                    We work closely with you to transform initial ideas into well-defined concepts. Our team conducts brainstorming sessions and workshops to explore possibilities, identify opportunities, and refine your vision into actionable plans that align with your business objectives.
                  </li>
                    <br />
                  <li>
                    <span> User Research and Analysis</span>
                   
                    Understanding your target audience is key to successful design. We conduct thorough user research and analysis to gather insights into user needs, behaviors, and preferences. This information informs our design decisions, ensuring that the end product effectively addresses user pain points.
                  </li>
                  <br />
                  <li>
                    <span>Wireframing and Prototyping</span>                    
                        Our wireframing and prototyping services allow you to visualize your ideas before development begins. We create low-fidelity wireframes and interactive prototypes that illustrate the user journey and interface, providing a clear roadmap for the design process and facilitating early feedback.
                  </li>
                  <br />
                  <li>
                    <span>Visual Design</span>Cet
                    
                    We focus on creating visually compelling designs that reflect your brand identity and engage users. Our design team crafts stunning visuals, including color schemes, typography, and graphics, ensuring a cohesive and appealing aesthetic that enhances the user experience.
                  </li>
                  <br />
                  <li>
                    <span> User Experience (UX) Design</span>
                   
                    User experience is at the heart of our design philosophy. We prioritize intuitive navigation and usability, conducting usability testing to validate our designs and ensure they meet user expectations. Our UX design process enhances user satisfaction and encourages repeat engagement.
                  </li>
                  <br />
                  <li>
                    <span> Collaboration and Feedback</span>
                   
                    We believe in a collaborative approach that values your input at every stage of the design process. Our team actively seeks feedback to refine concepts and ensure that the final design aligns with your vision and objectives.
                  </li>
                  <br />
                  <li>
                    <span> Partner with Us for Innovative Solutions</span>
                        By choosing our ideation and designing services, you gain a partner dedicated to turning your ideas into reality. Let us help you create innovative solutions that captivate your audience and drive your business forward.
                  </li>
                </ul> */}
                {/* <p>
                  Un tableau de bord est un outil de gestion et d'évaluation de
                  l’organisation d'une entreprise. Il est généralement constitué
                  de plusieurs indicateurs de performance à des moments ou des
                  périodes données. Le pilotage de la performance permet ainsi
                  de comparer différents indicateurs et de les mettre en
                  perspective. Le tableau de bord est donc un outil précieux
                  lorsqu’il s’agit de surveiller ses performances et de
                  planifier ses ressources pour ainsi optimiser sa performance.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IdeationDetail;
