import React from "react";

const DetailsArea = () => {
  return (
    <section className="blog-details-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="blog-details-wrap">
              <div className="bd-content-bottom">
                <h2 className="title">
                Empower Your Business with State-of-the-Art Mobile Applications
                </h2>
                <p>
                    In today's fast-paced digital landscape, having a powerful mobile application is crucial for engaging your audience and driving business success. Our mobile app development services are tailored to create high-performance apps that deliver seamless experiences across various devices.
                </p>
               
               
                {/* <ul className="list-wrap">
                  <li>
                    <span>iOS App Development</span>We specialize in crafting high-quality applications tailored specifically for the iPhone and iPad platforms. Our team of experienced developers utilizes the latest technologies and best practices to ensure your app not only meets Apple's stringent guidelines but also provides an exceptional user experience. We focus on performance optimization and seamless integration with iOS features, ensuring your app stands out in the App Store.
                  </li>
                    <br />
                  <li>
                    <span>Android App Development</span>Our robust Android app development services are designed to create applications that perform flawlessly on a wide range of Android devices. We understand the diverse ecosystem of Android, and our developers leverage this knowledge to deliver scalable, high-performing apps that resonate with your target audience. From design to deployment, we ensure your app is optimized for every device, providing users with a smooth and engaging experience.
                  </li>
                  <br />
                  <li>
                    <span>Cross-Platform App Development</span>Reach a broader audience with our cross-platform app development solutions, which allow you to build applications that function seamlessly across multiple operating systems using a single codebase. This approach not only saves time and resources but also ensures consistency in user experience, enabling you to engage users on both iOS and Android platforms without sacrificing quality.
                  </li>
                  <br />
                  <li>
                    <span>App Design and UX/UI</span>Cet
                    User engagement begins with a captivating design. Our expert designers focus on creating user-friendly interfaces that are both visually appealing and intuitive. We prioritize user experience (UX) and user interface (UI) design to enhance usability and keep users coming back. By understanding your audience's needs, we craft designs that not only attract but also retain users, fostering long-term relationships with your brand.
                  </li>
                  <br />
                  <li>
                    <span>App Maintenance and Updates</span>The launch of your app is just the beginning. We provide ongoing maintenance and regular updates to ensure your application remains functional, secure, and competitive in an ever-evolving market. Our dedicated support team is always available to address any issues and implement new features based on user feedback, ensuring your app continues to meet the needs of your audience.
                  </li>
                  <br />
                  <li>
                    <span>From Concept to Deployment</span>
                    We take pride in guiding you through every step of the mobile app development process. From initial concept and strategy to design, development, and deployment, our team is dedicated to delivering mobile applications that captivate users and drive your business forward. Partner with us to unlock the full potential of mobile technology and elevate your brand in the digital space.
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DetailsArea;
