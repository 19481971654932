import React from 'react'
import Layout from '../../layouts/Layout'
import BreadcrumbArea from '../../components/BreadcrumbArea/BreadcrumbArea'
import ProjectOne from './ProjectOne'
import ProjectTwo from './ProjectTwo'
import ProjectThree from './ProjectThree'
import ProjectFour from './ProjectFour'
import ProjectFive from './ProejctFive'
import ProjectSix from './ProjectSix'

function Projects() {
  return (
    <Layout header={1} footer={1} className="" mainClassName="">
    <BreadcrumbArea
     title={"Projects"}
     subtitle={"Projects"}
     showShape={false}
     className={" breadcrumb-area-two pt-175 pb-150"}
   />
    <ProjectOne/>
    <ProjectTwo/>
    <ProjectThree/>
    <ProjectFour/>
    <ProjectFive/>
    <ProjectSix/>
 </Layout>
  )
}

export default Projects