import React from "react";

const ProjectTwo = () => {
  return (
    <section className="about-area pb-50 pt-100">
      <div className="container custom-container">
        <div className="about-inner">
          <div className="row align-items-center justify-content-center">
            <div className="col-46 order-0 order-lg-2">
              <div className="about-img text-end">
                <img src="/img/images/about_img.jpg" alt="" />
              </div>
            </div>
            <div className="col-54">
              <div className="about-content">
                <div className="section-title mb-25">
                  <span className="sub-title">Document Management System</span>
                  
                  (Business & Finance)
                  
                </div>
                <p>
                We are excited to share our latest project for a Chartered Accountancy (CA) firm, aimed at enhancing document management for both the firm and its clients.
                </p>
                <h5>Project Objectives:</h5>
                <p>
                The primary goal of this project is to create a robust document upload and management system that enables users to:
                </p>
                <h5>Upload and Download Documents:</h5>
                <p>
                Clients can easily upload their documents and download important files at their convenience.

                </p>
                <h5>
                Manage Documents:
                </h5>
                <p> 
 The system provides a user-friendly interface for effective document management, ensuring that all files are organized and easily accessible.
</p>

        <h5>
        Technology Stack:
        </h5>
        <p>
        To achieve these objectives, we utilized the latest technologies from the MERN stack (MongoDB, Express.js, React.js, Node.js) along with other essential libraries and services, ensuring a seamless and efficient user experience across both a web application and an Android mobile app.
        </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectTwo;
