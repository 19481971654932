import React from "react";

const DigitalDetail = () => {
  return (
    <section className="blog-details-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="blog-details-wrap">
              <div className="bd-content-bottom">
                <h2 className="title">
                  Transform Your Business with Innovative Digital Solutions
                </h2>
                <p>
                  {/* In today’s fast-paced digital age, adapting and thriving requires a comprehensive approach to digital transformation. Our innovative digital solutions are designed to enhance your operational efficiency, drive growth, and ensure you stay ahead in a constantly evolving landscape. */}
                  In moment’s presto- paced digital age, conforming and thriving
                  requires a comprehensive approach to digital metamorphosis.
                  Our innovative digital results are designed to enhance your
                  functional effectiveness, drive growth, and insure you stay
                  ahead in a constantly evolving geography.
                </p>
                {/* <h2 className="title">
                    Our Comprehensive Mobile App Development Services Include:
                </h2> */}
                {/* <ul className="list-wrap">
                  <li>
                    <span>SEO (Search Engine Optimization)</span>
                    Improve your website’s visibility and ranking on search engines with our tailored SEO strategies. We focus on optimizing your site’s content, structure, and performance to attract organic traffic, ensuring your business reaches its target audience effectively and gains a competitive edge.
                  </li>
                    <br />
                  <li>
                    <span>Google Ads Management</span>
                    Maximize your online presence with our targeted Google Ads management services. We create and manage advertising campaigns that drive traffic to your site and increase conversions. By utilizing data-driven strategies, we ensure your ads reach the right audience at the right time, optimizing your return on investment.
                  </li>
                  <br />
                  <li>
                    <span>Social Media Marketing</span>
                    Engage with your audience through our dynamic social media marketing strategies. We craft tailored campaigns that connect with your target demographic, build brand awareness, and foster community engagement. Our approach helps you create meaningful interactions that enhance customer loyalty and promote your brand’s values.
                  </li>
                  <br />
                  <li>
                    <span>Content Marketing</span>
                    Attract and retain customers with our content marketing services. We focus on creating and distributing valuable, relevant content that resonates with your audience. From blog posts and infographics to videos and eBooks, our content strategies are designed to educate, inform, and inspire action, establishing your brand as an industry leader.
                  </li>
                  <br />
                  <li>
                    <span>Digital Strategy Development</span>
                    Our comprehensive digital strategy development services align your digital initiatives with your overall business objectives. We assess your current digital landscape, identify opportunities for improvement, and create actionable plans that drive success and ensure your digital transformation efforts are effective and measurable.
                  </li>
                  <br />
                  <li>
                    <span>Embrace Digital Transformation</span>
                    Embrace the power of digital transformation with solutions designed to enhance efficiency and drive growth. Our team is committed to helping you navigate the digital landscape, equipping your business with the tools and strategies needed to succeed in an ever-changing environment.
                  </li>
                </ul> */}
                {/* <p>
                  Un tableau de bord est un outil de gestion et d'évaluation de
                  l’organisation d'une entreprise. Il est généralement constitué
                  de plusieurs indicateurs de performance à des moments ou des
                  périodes données. Le pilotage de la performance permet ainsi
                  de comparer différents indicateurs et de les mettre en
                  perspective. Le tableau de bord est donc un outil précieux
                  lorsqu’il s’agit de surveiller ses performances et de
                  planifier ses ressources pour ainsi optimiser sa performance.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DigitalDetail;
