import React from 'react'
import Layout from '../../../layouts/Layout'
import ItDetailArea from './ItDetailArea'
import ItImage from './ItImage'
import BreadcrumbArea from '../../../components/BreadcrumbArea/BreadcrumbArea'
import ItCard from './ITCard'

function ItConsulting() {
  return (
    <Layout header={1} footer={1} className="" mainClassName="">
    <BreadcrumbArea
     title={"IT Consulting"}
     subtitle={"services/ it-consulting"}
     showShape={false}
     className={" breadcrumb-area-two pt-175"}
   />
  <ItImage/>
  <ItDetailArea/>
  <ItCard/>
 </Layout>
  )
}

export default ItConsulting