import React from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

export default function DevopsCard() {
  React.useEffect(() => {
    AOS.init({
      duration: 3000, // Duration of animations
      once: false, // Whether animation should happen only once - while scrolling down
    });
  }, []);

  const cardStyle = {
    border: "1px solid #ccc", // Light gray border
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Light shadow
    padding: "20px",
    borderRadius: "8px", // Optional rounded corners
    transition: "box-shadow 0.3s ease", // Smooth shadow transition
    textAlign: "center", // Center align content
    backgroundColor: "#fff", // White background
    height: "400px",
  };

  const cardHoverStyle = {
    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.2)", // Stronger shadow on hover
  };

  return (
    <>
      <section className="overflow-hidden">
        <div className="container mb-100">
          <div className="row justify-content-center g-4">
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <div
                className="color1"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/devOpsCards/Continuous Integration and Continuous Deployment (CICD).png"
                  height="100px"
                  alt=""
                />

                <div className="">
                  <h3>
                    Continuous Integration and Continuous Deployment (CI/CD)
                  </h3>
                  <p>
                    We implement CI/CD pipelines to automate code integration
                    and deployment, enabling faster releases and reducing
                    errors. This allows teams to focus on delivering
                    high-quality software efficiently.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div
                className="color2"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/devOpsCards/Infrastructure as Code.png"
                  height="100px"
                  alt=""
                />

                <div className="textcont">
                  <h3>Infrastructure as Code (IaC)</h3>
                  <p>
                    Our IaC services manage and provision infrastructure using
                    code, promoting consistency and scalability. This approach
                    reduces manual errors and enhances resource management
                    across environments.
                  </p>
                  {/* <a href="javascript:;">Read More</a> */}
                </div>
              </div>
            </div>

            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div
                className="color4"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                {" "}
                <img
                  src="/img/devOpsCards/Automated Testing.png"
                  height="100px"
                  alt=""
                />
                <div className="">
                  <h3>Automated Testing</h3>
                  <p>
                    We prioritize automated testing to insure software quality
                    and trustability. Our fabrics catch issues beforehand in
                    development, furnishing rapid-fire feedback and reducing
                    time to vend
                  </p>
                  {/* <a href="javascript:;">Read More</a> */}
                </div>
              </div>
            </div>
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <div
                className="color5"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                {" "}
                <img
                  src="/img/devOpsCards/Monitoring and Logging.png"
                  height="100px"
                  alt=""
                />
                <div className="">
                  <h3>Monitoring and Logging</h3>
                  <p>
                    Our monitoring results offer real- time perceptivity into
                    operation and structure performance. Comprehensive tools
                    help identify issues proactively, optimize coffers, and
                    insure a flawless stoner experience.
                  </p>
                  {/* <a href="javascript:;">Read More</a> */}
                </div>
              </div>
            </div>
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div
                className="color6"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                {" "}
                <img
                  src="/img/devOpsCards/Collaboration and Communication.png"
                  height="100px"
                  alt=""
                />
                <div className="textcont">
                  <h3>Collaboration and Communication</h3>
                  <p>
                    Promoting collaboration and communication is key to
                    successful DevOps. We implement tools and practices that
                    enhance transparency and teamwork, breaking down silos to
                    align teams toward common goals.
                  </p>
                  {/* <a href="javascript:;">Read More</a> */}
                </div>
              </div>
            </div>

            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div
                className="color7"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                {" "}
                <img
                  src="/img/devOpsCards/contineous improvement.png"
                  height="100px"
                  alt=""
                />
                <div className="">
                  <h3>Continuous Improvement</h3>
                  <p>
                    Influence our DevOps moxie to transfigure your development
                    lifecycle and deliver high- quality software briskly.
                    Partner with us to embrace a DevOps culture that accelerates
                    your digital metamorphosis.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div
                className="color7"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                {" "}
                <img
                  src="/img/devOpsCards/partner with us for a devop success.png"
                  height="100px"
                  alt=""
                />
                <div className="">
                  <h3>Partner with Us for DevOps Success</h3>
                  <p>
                    Leverage our DevOps expertise to transform your development
                    lifecycle and deliver high-quality software faster. Partner
                    with us to embrace a DevOps culture that accelerates your
                    digital transformation.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
