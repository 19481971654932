import React from 'react'
import Layout from '../../../layouts/Layout'
import BreadcrumbArea from '../../../components/BreadcrumbArea/BreadcrumbArea'
import SoftwareDetail from './SoftwareDetail.jsx'
import SoftwareImageComp from './SoftwareImageComp.jsx'
import SoftwareCard from './SoftwareCard.jsx'

function SoftwareDevelopment() {
  return (
    <Layout header={1} footer={1} className="" mainClassName="">
    <BreadcrumbArea
     title={"Software Development"}
     subtitle={"services/ software-development"}
     showShape={false}
     className={" breadcrumb-area-two pt-175"}
   />
  <SoftwareImageComp/>
  <SoftwareDetail/>
  <SoftwareCard/>
 </Layout>
  )
}

export default SoftwareDevelopment