import React from 'react'
import Layout from '../../../layouts/Layout'
import IdeationImage from './IdeationImage'
import IdeationDetail from './IdeationDetail'
import BreadcrumbArea from '../../../components/BreadcrumbArea/BreadcrumbArea'
import IdeationCard from './IdeationCard'

function IdeationandDesigning() {
  return (
    <Layout header={1} footer={1} className="" mainClassName="">
    <BreadcrumbArea
     title={"Ideation and Designing"}
     subtitle={"services/ ideation-designing"}
     showShape={false}
     className={" breadcrumb-area-two pt-175"}
   />
  <IdeationImage/>
  <IdeationDetail/>
  <IdeationCard/>
 </Layout>
  )
}

export default IdeationandDesigning