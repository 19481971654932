import React from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
// import card1 from '../../../../public/img/CloudManageCard/proactivemonitoring.png'

export default function CloudCard() {
  React.useEffect(() => {
    AOS.init({
      duration: 3000, // Duration of animations
      once: false, // Whether animation should happen only once - while scrolling down
    });
  }, []);

  const cardStyle = {
    border: "1px solid #ccc", // Light gray border
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Light shadow
    padding: "20px",
    borderRadius: "8px", // Optional rounded corners
    transition: "box-shadow 0.3s ease", // Smooth shadow transition
    textAlign: "center", // Center align content
    backgroundColor: "#fff", // White background
    height: "350px",
  };

  const cardHoverStyle = {
    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.2)", // Stronger shadow on hover
  };

  return (
    <>
      <section className="overflow-hidden">
        <div className="container mb-100">
          <div className="row justify-content-center g-4">
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <div
                className="color1"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/CloudManageCard/proactivemonitoring.png"
                  height="100px"
                  alt=""
                />

                <div className="">
                  <h3>Proactive Monitoring</h3>
                  <p>
                    We provide proactive monitoring to continuously observe your
                    cloud infrastructure for performance issues and threats.
                    Advanced tools help us detect anomalies and respond swiftly,
                    minimizing downtime.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div
                className="color2"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/CloudManageCard/performanceoptimization.png"
                  height="100px"
                  alt=""
                />

                <div className="textcont">
                  <h3>Performance Optimization</h3>
                  <p>
                    Our team specializes in optimizing cloud performance through
                    regular assessments. We identify bottlenecks and implement
                    enhancements to ensure your applications meet business
                    demands effectively.
                  </p>
                  {/* <a href="javascript:;">Read More</a> */}
                </div>
              </div>
            </div>

            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div
                className="color4"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/CloudManageCard/securityManagement.png"
                  height="100px"
                  alt=""
                />

                <div className="">
                  <h3>Security Management</h3>
                  <p>
                    Protecting your cloud data is our top priority. We offer
                    continuous threat detection, vulnerability assessments, and
                    compliance checks to safeguard your assets from cyber
                    threats.
                  </p>
                  {/* <a href="javascript:;">Read More</a> */}
                </div>
              </div>
            </div>
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <div
                className="color5"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/CloudManageCard/CostManagement.png"
                  height="100px"
                  alt=""
                />

                <div className="">
                  <h3>Cost Management</h3>
                  <p>
                    Gain control over pall spending with our cost operation
                    services. We dissect operation patterns to optimize resource
                    allocation, reducing gratuitous charges while meeting your
                    business requirements.
                  </p>
                  {/* <a href="javascript:;">Read More</a> */}
                </div>
              </div>
            </div>
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div
                className="color6"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/CloudManageCard/BackupAndDisasterRecovery.png"
                  height="100px"
                  alt=""
                />

                <div className="textcont">
                  <h3>Backup and Disaster Recovery</h3>
                  <p>
                    Our backup and disaster recovery results insure data
                    security and easy recovery during dislocations. We apply
                    robust strategies to maintain business durability and cover
                    against data loss.
                  </p>
                  {/* <a href="javascript:;">Read More</a> */}
                </div>
              </div>
            </div>

            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div
                className="color7"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/CloudManageCard/cloudGoverence.png"
                  height="100px"
                  alt=""
                />

                <div className="">
                  <h3>Cloud Governance</h3>
                  <p>
                    Effective Pall governance is essential for compliance and
                    effectiveness. We help produce fabrics and programs that
                    promote responsibility and insure stylish practices across
                    your pall terrain.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div
                className="color7"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/CloudManageCard/PartnerWithUsForCloudExcellence.png"
                  height="100px"
                  alt=""
                />

                <div className="">
                  <h3>Partner with Us for Cloud Excellence</h3>
                  <p>
                    Choosing our Cloud Managed Services means gaining a
                    dedicated partner focused on optimizing your cloud strategy.
                    Let us enhance your cloud environment while you focus on
                    innovation and growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
