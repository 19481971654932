import React from "react";
import AboutArea from "../../components/About/AboutArea";
import BannerOne from "../../components/Banner/BannerOne";
import ConsultationArea from "../../components/Consultation/ConsultationArea";
import ProjectArea from "../../components/Project/ProjectArea";
import ServicesArea from "../../components/ServicesArea/ServicesArea";
import TeamArea from "../../components/Team/TeamArea";
import Layout from "../../layouts/Layout";
import CounterWrapFour from "../../components/CounterArea/CounterWrapFour";
import InnerServicesArea from "../../components/ServicesArea/InnerServicesArea";
import ToolsArea from "../../components/ToolsArea/ToolsArea";
import TestimonialAreaFour from "../../components/Testimonial/TestimonialAreaFour";
import { Helmet } from "react-helmet";

const CreativeAgency = () => {
  return (
    <>
      <Helmet>
        <meta property="og:title" content="Creative Agency - KodeKlan" />
        <meta
          property="og:description"
          content="Welcome to Creative Agency - KodeKlan"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://kodeklan.com/creative-agency"
        />
        <meta
          property="og:image"
          content="https://kodeklan.com/img/banner/logo_kodeklan.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Creative Agency - KodeKlan" />
      </Helmet>
      <Layout header={1} footer={1}>
        <BannerOne />
        <ServicesArea />
        <AboutArea />
        <CounterWrapFour />
        <TeamArea />
        <ProjectArea />
        {/* <ServicesAreaItem/> */}
        <InnerServicesArea />
        <ToolsArea />
        {/* <TestimonialArea /> */}
        <TestimonialAreaFour />
        <ConsultationArea />
        {/* <BlogArea /> */}
        {/* <NewsLetterArea /> */}
      </Layout>
    </>
  );
};

export default CreativeAgency;
