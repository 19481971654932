import React from "react";

const TestimonialAreaThreeItem = ({id,name, description}) => {
  return (
    <div className="swiper-slide">
      <div className="testimonial-item-three">
        <div className="testimonial-thumb-three">
          <img src={`/img/images/testimonial_avatar0${id}.png`} alt="" />
        </div>

        <div className="testimonial-content-three">
          <h4 className="title">{name}</h4>

          {/* <span>Product Designer</span> */}

          <p>
            “{description}”
          </p>
        </div>
      </div>
    </div>
  );
};

export default TestimonialAreaThreeItem;
