import React from "react";
import { Link } from "react-router-dom";
// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiperArrows } from "../../lib/hooks/useSwiperArrows";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const ProjectArea = () => {
  const swiper_settings = {
    spaceBetween: 2,
    slidesPerView: 2,
    loop: true,
    autoplay: {
      delay: 3000, // 3 seconds
      disableOnInteraction: false,
    },
    breakpoints: {
      300: {
        slidesPerView: 1.4,
        spaceBetween: 20,
      },
      500: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      992: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1500: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };

  const { sliderRef, toNext, toPrev } = useSwiperArrows();

  return (
    <section className="project-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-4">
            <div className="project-content">
              <div className="section-title white-title mb-30">
                <span className="sub-title">Our Complete Craft</span>
                <h4 className="title">Our Valued Clients</h4>
              </div>
              <p>
                At Kodeklan, we take pride in collaborating with diverse clients,
                helping them achieve their digital objectives with tailored
                solutions
              </p>
              <div className="content-bottom">
                <Link to="/projects" className="btn">
                  View All Projects <span></span>
                </Link>

                <div className="project-nav">
                  <button
                    className="swiper-button-prev"
                    onClick={toPrev}
                  ></button>
                  <button
                    className="swiper-button-next"
                    onClick={toNext}
                  ></button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-8">
            <div className="project-item-wrap">
              <Swiper
                {...swiper_settings}
                onBeforeInit={(swiper) => {
                  sliderRef.current = swiper;
                }}
              >
                {[ 2, 3,4,2,3,4].map((x, index) => (
                  <SwiperSlide key={x + index}>
                    <div className="project-item">
                      <Link to="/projects">
                        <img
                          src={`/img/project/project_img0${x}.png`}
                          alt={`Project ${x}`}
                        />
                      </Link>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      {/* You can uncomment this if you need the shapes in your design */}
      {/* <div className="project-shape-wrap">
        <img
          src="/img/project/project_shape01.png"
          alt=""
          className="shape-one ribbonRotate"
        />
        <img
          src="/img/project/project_shape02.png"
          alt=""
          className="shape-two ribbonRotate"
        />
      </div> */}
    </section>
  );
};

export default ProjectArea;
