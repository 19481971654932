import React from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

export default function DigitalCard() {
  React.useEffect(() => {
    AOS.init({
      duration: 3000, // Duration of animations
      once: false, // Whether animation should happen only once - while scrolling down
    });
  }, []);

  const cardStyle = {
    border: "1px solid #ccc", // Light gray border
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Light shadow
    padding: "20px",
    borderRadius: "8px", // Optional rounded corners
    transition: "box-shadow 0.3s ease", // Smooth shadow transition
    textAlign: "center", // Center align content
    backgroundColor: "#fff", // White background
    height: "350px",
  };

  const cardHoverStyle = {
    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.2)", // Stronger shadow on hover
  };
  return (
    <>
      <section className="overflow-hidden">
        <div className="container mb-100">
          <div className="row justify-content-center g-4">
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <div
                className="color1"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                {" "}
                <img
                  src="/img/digitalTransformationCards/SEO (Search Engine Optimization).png"
                  height="100px"
                  alt=""
                />
                <div className="">
                  <h3>SEO (Search Engine Optimization)</h3>
                  <p>
                    Boost your website’s visibility with our acclimatized SEO
                    strategies. We optimize content, structure, and performance
                    to attract organic business, icing effective followership
                    reach and competitive advantage.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div
                className="color2"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                {" "}
                <img
                  src="/img/digitalTransformationCards/google Ads Management.png"
                  height="100px"
                  alt=""
                />
                <div className="textcont">
                  <h3>Google Ads Management</h3>
                  <p>
                    Maximize your online presence with our targeted Google
                    Advertisements services. We produce and manage juggernauts
                    that drive business and increase transformations, exercising
                    data- driven strategies for optimal ROI.
                  </p>
                  {/* <a href="javascript:;">Read More</a> */}
                </div>
              </div>
            </div>

            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div
                className="color4"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                {" "}
                <img
                  src="/img/digitalTransformationCards/social media management.png"
                  height="100px"
                  alt=""
                />
                <div className="">
                  <h3>Social Media Marketing</h3>
                  <p>
                    Engage your audience with dynamic social media marketing.
                    Our tailored campaigns build brand awareness and foster
                    community engagement, creating meaningful interactions that
                    enhance customer loyalty.
                  </p>
                  {/* <a href="javascript:;">Read More</a> */}
                </div>
              </div>
            </div>
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <div
                className="color5"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                {" "}
                <img
                  src="/img/digitalTransformationCards/content marketing.png"
                  height="100px"
                  alt=""
                />
                <div className="">
                  <h3>Content Marketing</h3>
                  <p>
                    Attract and retain customers through our content marketing
                    services. We create valuable, relevant content that
                    resonates with your audience, establishing your brand as an
                    industry leader.
                  </p>
                  {/* <a href="javascript:;">Read More</a> */}
                </div>
              </div>
            </div>
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div
                className="color6"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                {" "}
                <img
                  src="/img/digitalTransformationCards/Digital strategy development.png"
                  height="100px"
                  alt=""
                />
                <div className="textcont">
                  <h3>Digital Strategy Development</h3>
                  <p>
                    We align your digital enterprise with business objects
                    through comprehensive strategy development. By assessing
                    your geography, we identify enhancement openings and produce
                    practicable plans for success.
                  </p>
                  {/* <a href="javascript:;">Read More</a> */}
                </div>
              </div>
            </div>

            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div
                className="color7"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                {" "}
                <img
                  src="/img/digitalTransformationCards/Embrace Digital Transformation.png"
                  height="100px"
                  alt=""
                />
                <div className="">
                  <h3>Embrace Digital Transformation</h3>
                  <p>
                    Utilize the power of digital metamorphosis with results that
                    enhance effectiveness and drive growth. Our platoon is
                    devoted to equipping your business with the tools demanded
                    to succeed in a changing terrain.
                  </p>
                </div>
              </div>
            </div>

            {/* <div className="col-sm-4 col-xs-12 aos-init" data-aos="fade-left" data-aos-duration="3000">
                                <div className="color7"  style={cardStyle}
              onMouseEnter={e => e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow}
              onMouseLeave={e => e.currentTarget.style.boxShadow = cardStyle.boxShadow}>
                                    <img src="" width="100%" alt="" />
                                    <div className="">
                                        <h4>Partner with Us for Innovative Solutions</h4>
                                        <p>
                                        Choosing our ideation and design services means partnering with a team dedicated to turning your ideas into reality. Let us help you create solutions that captivate your audience and drive your business forward.
                                        </p>
                                       
                                    </div>
                                </div>
                            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}
