import React from 'react'
import Layout from '../../../layouts/Layout'
import BreadcrumbArea from '../../../components/BreadcrumbArea/BreadcrumbArea'
import DigitalImage from './DigitalImage'
import DigitalDetail from './DigitalDetail'
import DigitalCard from './DigitalCard'

function DigitalTransformation() {
  return (
    <Layout header={1} footer={1} className="" mainClassName="">
    <BreadcrumbArea
     title={"Digital Transformation"}
     subtitle={"services/ digital-Transformation"}
     showShape={false}
     className={" breadcrumb-area-two pt-175"}
   />
  <DigitalImage/>
  <DigitalDetail/>
  <DigitalCard/>
 </Layout>
  )
}

export default DigitalTransformation