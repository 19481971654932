import React from "react";
import CounterAreaThreeItem from "./CounterAreaThreeItem";

const CounterAreaThree = () => {
  const amount = [10, 8, 10, 20];
  const counter_items = [
    {
      src: "/img/icon/inner_counter_icon01.png",
      titles: ["Satisfied", "Customers"],
      // amount:10,
    },
    {
      src: "/img/icon/inner_counter_icon02.png",
      titles: ["Project", "Finished"],
      // amount:8,
    },
    {
      src: "/img/icon/inner_counter_icon03.png",
      titles: ["our", "Employees"],
      // amount:10,
    },
    {
      src: "/img/icon/inner_counter_icon04.png",
      titles: ["Industries"],
      // amount:20,
    },
  ];

  return (
    <section className="counter-area-three">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-0 order-lg-2">
            <div className="counter-item-wrap-three">
              <ul className="list-wrap">
                {counter_items.map((x, index) => (
                  <li key={index}>
                    <CounterAreaThreeItem item={x} amount={amount[index]} />
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="counter-content-three">
              {/* <div className="section-title title-style-two mb-30">
                <h2 className="title">
                  Content Has to Be More That Just Brilliant
                </h2>
              </div> */}
              <h2 className="display-6 fs-5">
                At KodeKlan, we are passionate about turning your digital dreams
                into reality. We specialize in crafting tailored websites and
                delivering a full suite of IT services designed to empower
                businesses in today’s fast-paced digital landscape. What sets us
                apart is our commitment to making technology accessible to
                everyone, especially startups and small businesses. We
                understand the challenges of working within a budget, and we’re
                here to ensure that innovation doesn’t come at a hefty price.
                Our team blends creativity, technical expertise, and strategic
                insight to help you not just launch your ideas but scale them
                into impactful solutions. From dynamic websites to robust IT
                infrastructures, we’re dedicated to helping you thrive in a
                competitive market. Your success is our mission, and we’re here
                to guide you every step of the way—because at Kodeklan Private
                Limited, we don’t just deliver projects, we build partnerships
                for the future.
              </h2>
              {/* <img src="/img/images/sine.png" alt="" />
              <div className="content-bottom">
                <h4 className="title-two">Davis Levin</h4>
                <span>Director Company</span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CounterAreaThree;
