import React from "react";

function Terms() {
  return (
    <section className="blog-details-area pb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="blog-details-wrap">
              <div className="bd-content-bottom">
                <h2 className="">1. Introduction</h2>
                <p>
                  These Terms and Conditions govern your use of our website and
                  services. By accessing or using Kodeklan, you agree to comply
                  with these terms. If you do not agree, please refrain from
                  using our website.
                </p>
                <h2 className="">2. Purpose of the Website</h2>
                <p>
                  Kodeklan offers a tailored suite of services, including web
                  development and software development. Our dedicated team
                  collaborates closely with you to create innovative,
                  personalized solutions that enhance your business efficiency
                  and drive growth.
                </p>

                <h2 className="">3. User Responsibilities</h2>
                <p>
                  As a user of Kodeklan, you agree to: Provide accurate and
                  complete information when interacting with our services.
                  Maintain the confidentiality of your account credentials and
                  take responsibility for all activities under your account.
                </p>
                <h2>4. Intellectual Property</h2>
                <p>
                  All content, features, and functionalities on Kodeklan,
                  including but not limited to text, graphics, logos, and
                  software, are the exclusive property of Kodeklan or our
                  licensors. You may not reproduce, distribute, or create
                  derivative works without our express written permission.
                </p>
                <h2>5. User Content</h2>
                <p>
                  You may have the opportunity to submit content (e.g.,
                  feedback, comments) to Kodeklan. By submitting content, you
                  grant us a non-exclusive, royalty-free, perpetual license to
                  use, modify, and publish that content in any form.
                </p>
                <h2>6. Prohibited Activities</h2>
                <p>
                  Users are prohibited from engaging in the following
                  activities: Spamming, phishing, or transmitting malicious
                  software. Attempting to gain unauthorized access to any part
                  of the website or other systems. Engaging in any illegal
                  activities or violating applicable laws.
                </p>
                <h2>7. Limitation of Liability</h2>
                <p>
                  To the fullest extent permitted by law, Kodeklane shall not be
                  liable for any direct, indirect, incidental, or consequential
                  damages arising from your use of the website or services.
                </p>
                <h2>8. Modifications to Terms</h2>
                <p>
                  We reserve the right to modify these Terms and Conditions at
                  any time. Any changes will be posted on this page, and your
                  continued use of the website constitutes acceptance of those
                  changes.
                </p>
                <h2>9. Governing Law</h2>
                <p>
                  These Terms and Conditions shall be governed by and construed
                  in accordance with the laws of Uttar Pradesh State of India.
                  Any disputes arising from these terms shall be subject to the
                  exclusive jurisdiction of the courts located in Noida Uttar
                  Pradesh.
                </p>
                <h2>10. Contact Information</h2>
                <p>
                  For any questions or concerns regarding these Terms and
                  Conditions, please contact us at hello@kodeklan.com or call us
                  at +91-1204774269.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Terms;
