import React from 'react'
import BreadcrumbArea from '../../components/BreadcrumbArea/BreadcrumbArea'
import Layout from '../../layouts/Layout'
import Terms from './Terms'

function TermsCondition() {
  return (
    <Layout header={1} footer={1} className="" mainClassName="">
       <BreadcrumbArea
        title={"Terms Condition"}
        subtitle={"Terms Condition"}
        showShape={false}
        className={" breadcrumb-area-two pt-175 pb-150"}
      />
        <Terms/>

    </Layout>
  )
}

export default TermsCondition