import React from "react";
import InnerServicesAreaItem from "./InnerServicesAreaItem";

const InnerServicesArea = () => {
  const inner_services = [
    // {
    //   src: "/img/icon/healthcare.png",
    //   url: "#",
    //   title: "Healthcare",
    //   // desc: "React, Veu.js, Angular",
    // },
    {
      src: "/img/icon/finanace.png",
      url: "#",
      title: "Finance",
      // desc: "Node.js, Django, Ruby on Rails",
    },
    {
      src: "/img/icon/restaurant_1586085.png",
      url: "#",
      title: "Restaurant",
      // desc: "React Native, Flutter, Swift",
    },
    {
      src: "/img/icon/ecommerce.png",
      url: "#",
      title: "eCommerce",
      // desc: "MySQL, PostgreSQL, MongoDB",
    },
    {
      src: "/img/icon/travel.png",
      url: "#",
      title: "Traval",
      // desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      src: "/img/icon/marketing.png",
      url: "#",
      title: "Marketing",
      // desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      src: "/img/icon/retail.png",
      url: "#",
      title: "Retail",
      // desc: "AWS S3, Google Cloud Storage, Azure Blob Storage",
    },
    {
      src: "/img/icon/real-estate.png",
      url: "#",
      title: "Real Estate",
      // desc: "Docker, Kubernetes, Ansible",
    },
    {
      src: "/img/icon/insurance.png",
      url: "#",
      title: "Insurance",
      // desc: "Docker, Kubernetes, Ansible",
    },
    {
      src: "/img/icon/aviation.png",
      url: "#",
      title: "Aviation",
      // desc: "Docker, Kubernetes, Ansible",
    },
    {
      src: "/img/icon/education.png",
      url: "#",
      title: "Education",
      // desc: "Docker, Kubernetes, Ansible",
    },
    // {
    //   src: "/img/icon/entertainment.png",
    //   url: "#",
    //   title: "Entertainment",
    //   // desc: "Docker, Kubernetes, Ansible",
    // },
    
  ];

  return (
    <section className="inner-services-area pt-110 pb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title title-style-two text-center mb-60">
              {/* <span className="sub-title">Graphic Service</span> */}
              <h5 className="title">Industries We Serve</h5>
            </div>
          </div>
        </div>

        <div className="inner-services-item-wrap">
          <div className="row justify-content-center">
            {inner_services.map((x, index) => (
              <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                <InnerServicesAreaItem item={x} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default InnerServicesArea;
