import React from "react";
import CounterWrapFourItem from "./CounterWrapFourItem";

const CounterWrapFour = () => {
  return (
    <div className="counter-item-wrap-four mt-50">
      <ul className="list-wrap">
        <li>
          <CounterWrapFourItem
            amount={10}
            suffix="+"
            title={
              <>
                Project Finished
                {/* <span>Accunting</span> */}
              </>
            }
          />
        </li>

        <li>
          <CounterWrapFourItem
            amount={8}
            suffix="+"
            title={
              <>
                {/* International <span>Programs</span> */}
                Satisfied Customer
              </>
            }
          />
        </li>

        <li>
          <CounterWrapFourItem
            amount={10}
            suffix="+"
            title={
              <>
                Industries
              </>
            }
          />
        </li>
        <li>
          <CounterWrapFourItem
            amount={20}
            suffix="+"
            title={
              <>
                Our Employees
              </>
            }
          />
        </li>
      </ul>
    </div>
  );
};

export default CounterWrapFour;
