import React from "react";
import { Link} from "react-router-dom";

const NotFoundPage = () => {

  return (
    <div
      style={{
        height: "100vh",
        // width: "94vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
      id="error-page"
    >
      <h1 style={{ color: "rgb(25, 118, 210)" }}>404 - Page Not Found!</h1>
      <p style={{ color: "#4B5563", marginTop: "1rem" }}>
        The page you are looking for does not exist
      </p>
      <Link to='/'>
      <button
        style={{
          marginTop: "1rem",
          padding: "0.5rem 1rem",
          fontSize: "1rem",
          color: "#fff",
          backgroundColor: "rgb(25, 118, 210)",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        Go to Home Page
      </button>

      </Link>
    </div>
  );
};

export default NotFoundPage;