import React from 'react'
import Layout from '../../../layouts/Layout'
import BreadcrumbArea from '../../../components/BreadcrumbArea/BreadcrumbArea'
import CloudImage from './CloudImage'
import CloudDetail from './CloudDetail'
import CloudCard from './CloudCard'

function CloudManagedServices() {
  return (
    <Layout header={1} footer={1} className="" mainClassName="">
    <BreadcrumbArea
     title={"Cloud Managed Services"}
     subtitle={"services/ cloud-managed-service"}
     showShape={false}
     className={" breadcrumb-area-two pt-175"}
   />
  <CloudImage/>
  <CloudDetail/>
  <CloudCard/>
 </Layout>
  )
}

export default CloudManagedServices