import React from 'react'
import Layout from '../../layouts/Layout'
import BreadcrumbArea from '../../components/BreadcrumbArea/BreadcrumbArea'
import Policies from './Policies'

function PrivacyPolicy() {
  return (
   <>
     <Layout header={1} footer={1} className="" mainClassName="">
       <BreadcrumbArea
        title={"Privacy Policy"}
        subtitle={"Privacy Policy"}
        showShape={false}
        className={" breadcrumb-area-two pt-175 pb-150"}
      />
        <Policies/>
     {/* <ImageComp/>
     <DetailsArea/> */}
      {/* <p>Empower Your Business with State-of-the-Art Mobile Applications
In today's fast-paced digital landscape, having a powerful mobile application is crucial for engaging your audience and driving business success. Our mobile app development services are tailored to create high-performance apps that deliver seamless experiences across various devices.
Our Comprehensive Mobile App Development Services Include:</p> */}
      {/*
      <CommunityArea />
      <TeamAreaThree />
      <NewsLetterAreaTwo />
      <BrandAreaThree /> */}


    </Layout>

   </>
  )
}

export default PrivacyPolicy