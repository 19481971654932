import React from 'react'
import Layout from '../../../layouts/Layout'
import BreadcrumbArea from '../../../components/BreadcrumbArea/BreadcrumbArea'
import DevopsImage from './DevopsImage'
import DevopsDetail from './DevopsDetail'
import DevopsCard from './DevopsCard'

function Devops() {
  return (
    <Layout header={1} footer={1} className="" mainClassName="">
    <BreadcrumbArea
     title={"Devops"}
     subtitle={"services/devops"}
     showShape={false}
     className={" breadcrumb-area-two pt-175"}
   />
  <DevopsImage/>
  <DevopsDetail/>
  <DevopsCard/>
 </Layout>
  )
}

export default Devops